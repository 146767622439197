const sampleAction = {
	id: 'idUploadReportAction',
	selectionType: 'general', // [single|multiple|general]
	label: 'action.uploadgeorep',
	functionality: 'WRITE_GEOREPREPORT', // set the functionality if needed

	runAction: function (action, model, data) {
		data.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-georepreportUploadReportAction-' + model.name + '-show', data);
	}
};

const invalidGeoreport = {
	id: 'invalidGeoreport',
	selectionType: 'multiple',
	label: 'action.invalidGeoreport',
	functionality: 'WRITE_GEOREPREPORT',
	checkAvailability: function (registries) {
		return registries.isvalid == 1 ? true : false;
	},
	runAction: function (action, model, registries) {
		const data = { registries, isAction: true };
		this.$puiEvents.$emit('pui-modalDialogForm-invalidGeoreport-' + model.name + '-show', data);
	}
};

const invalidGeoreportForm = {
	id: 'invalidGeoreportForm',
	selectionType: 'single',
	label: 'action.invalidGeoreport',
	functionality: 'WRITE_GEOREPREPORT',
	checkAvailability: function (row) {
		return row[0].isvalid == 1 ? true : false;
	},
	runAction: function (action, model, row) {
		const data = { row, isAction: true };
		this.$puiEvents.$emit('pui-modalDialogForm-invalidGeoreportForm-' + model.name + '-show', data);
	}
};

export default {
	gridactions: [sampleAction, invalidGeoreport],
	formactions: [sampleAction, invalidGeoreportForm]
};
