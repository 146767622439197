<template>
	<div class="pui-form">
		<georepreport-modals :modelName="modelName"></georepreport-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<georepreport-form-header :modelPk="modelPk"></georepreport-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col xs12 lg12>
					<v-row dense style="padding-top: 20px">
						<!-- AVESSELNAME -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`avesselname-georepreport`"
								v-model="model.avesselname"
								:label="$t('georepreport.avesselname')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- ACALLSIGN -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`acallsign-georepreport`"
								v-model="model.acallsign"
								:label="$t('georepreport.acallsign')"
								:disabled="formDisabled"
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<!-- AMMSI -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`ammsi-georepreport`"
								v-model="model.ammsi"
								:label="$t('georepreport.ammsi')"
								:disabled="formDisabled"
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<!-- AFLAG -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`aflag-georepreport`"
								v-model="model.aflag"
								:label="$t('georepreport.aflag')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>

					<v-row dense>
						<!-- BTIME -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`btime-georepreport`"
								v-model="model.btime"
								:label="$t('georepreport.btime')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
						<!-- CPOSITION -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`cposition-georepreport`"
								v-model="model.cposition"
								:label="$t('georepreport.cposition')"
								:disabled="formDisabled"
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<!-- DPOSITION -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`dposition-georepreport`"
								v-model="model.dposition"
								:label="$t('georepreport.dposition')"
								:disabled="formDisabled"
								toplabel
								maxlength="20"
							></pui-text-field>
						</v-col>
						<!-- ECOURSE -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`ecourse-georepreport`"
								v-model="model.ecourse"
								:label="$t('georepreport.ecourse')"
								:disabled="formDisabled"
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- FSPEED -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`fspeed-georepreport`"
								v-model="model.fspeed"
								:label="$t('georepreport.fspeed')"
								:disabled="formDisabled"
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-col>
						<!-- GDEPARTUREPORT -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`gdepartureport-georepreport`"
								v-model="model.gdepartureport"
								:label="$t('georepreport.gdepartureport')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- HENTRYGEOREP -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`hentrygeorep-georepreport`"
								v-model="model.hentrygeorep"
								:label="$t('georepreport.hentrygeorep')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- IDESTINATION -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`idestination-georepreport`"
								v-model="model.idestination"
								:label="$t('georepreport.idestination')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- IETA -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`ieta-georepreport`"
								v-model="model.ieta"
								:label="$t('georepreport.ieta')"
								:disabled="formDisabled"
								toplabel
								maxlength="6"
							></pui-text-field>
						</v-col>
						<!-- JPILOTONBOARD -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`jpilotonboard-georepreport`"
								v-model="model.jpilotonboard"
								:label="$t('georepreport.jpilotonboard')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
						<!-- KETDGEOREP -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`ketdgeorep-georepreport`"
								v-model="model.ketdgeorep"
								:label="$t('georepreport.ketdgeorep')"
								:disabled="formDisabled"
								toplabel
								maxlength="100"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- LROUTE -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-text-field
								:id="`lroute-georepreport`"
								v-model="model.lroute"
								:label="$t('georepreport.lroute')"
								:disabled="formDisabled"
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- MCOMMUNICATION -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-text-field
								:id="`mcommunication-georepreport`"
								v-model="model.mcommunication"
								:label="$t('georepreport.mcommunication')"
								:disabled="formDisabled"
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- NTIMENEXTREPORT -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`ntimenextreport-georepreport`"
								v-model="model.ntimenextreport"
								:label="$t('georepreport.ntimenextreport')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
						<!-- ODRAUGHT -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`odraught-georepreport`"
								v-model="model.odraught"
								:label="$t('georepreport.odraught')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- PCARGO -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-text-field
								:id="`pcargo-georepreport`"
								v-model="model.pcargo"
								:label="$t('georepreport.pcargo')"
								:disabled="formDisabled"
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- QDEFECTS -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<pui-text-field
								:id="`qdefects-georepreport`"
								v-model="model.qdefects"
								:label="$t('georepreport.qdefects')"
								:disabled="formDisabled"
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- RPOLLUTION -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`rpollution-georepreport`"
								v-model="model.rpollution"
								:label="$t('georepreport.rpollution')"
								:disabled="formDisabled"
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
						<!-- SWEATHER -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`sweather-georepreport`"
								v-model="model.sweather"
								:label="$t('georepreport.sweather')"
								:disabled="formDisabled"
								toplabel
								maxlength="300"
							></pui-text-field>
						</v-col>
						<!-- TVESSELOWNER -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`tvesselowner-georepreport`"
								v-model="model.tvesselowner"
								:label="$t('georepreport.tvesselowner')"
								:disabled="formDisabled"
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row dense>
						<!-- USIZETYPE -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`usizetype-georepreport`"
								v-model="model.usizetype"
								:label="$t('georepreport.usizetype')"
								:disabled="formDisabled"
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-col>
						<!-- VMEDICALPERSON -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`vmedicalperson-georepreport`"
								v-model="model.vmedicalperson"
								:label="$t('georepreport.vmedicalperson')"
								:disabled="formDisabled"
								toplabel
								maxlength="200"
							></pui-text-field>
						</v-col>
						<!-- WPERSONSONBOARD -->
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
							<pui-text-field
								:id="`wpersonsonboard-georepreport`"
								v-model="model.wpersonsonboard"
								:label="$t('georepreport.wpersonsonboard')"
								:disabled="formDisabled"
								toplabel
								maxlength="10"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row>
						<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="margin-bottom: 25px">
							<v-btn @click.native="clickButtonShowModal()" width="300px">View original report</v-btn>
						</v-col>
					</v-row>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import georepreportActions from './GeorepreportActions';
import georepreportModals from './GeorepreportModals.vue';

export default {
	name: 'georepreport-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'georepreport-modals': georepreportModals
	},
	data() {
		return {
			modelName: 'georepreport',
			actions: georepreportActions.formactions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		},
		clickButtonShowModal() {
			let dataToModal = {};
			dataToModal = this.model.fullreport;
			this.$puiEvents.$emit('pui-modalDialogForm-fullReportModal-' + this.modelName + '-show', dataToModal);
		}
	},
	computed: {
		georeptypeidItemsToSelect() {
			return [{ georeptypeid: this.model.georeptypeid }];
		}
	},
	created() {}
};
</script>
