<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.georepreport.uploadGeorep.title')"
			:modelName="modelName"
			:dialogName="georepreportUploadReportModal"
			:onOk="onOkgeorepreportUploadReportModal"
			:overflow="true"
			:widthDialog="1000"
		>
			<template slot="message" slot-scope="data">
				<v-row dense>
					<!-- REPORTID
					v-model="data.modalData.fullreport" esto se debe modificar-->
					<v-col cols="12">
						<pui-text-area
							:id="`reportid-georepreport`"
							v-model="data.modalData.fullreport"
							:label="$t('modal.georepreport.uploadGeorep.reporttext')"
							required
							toplabel
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.georepreport.fullReportModal.title')"
			:modelName="modelName"
			:dialogName="fullReportModal"
			:widthDialog="1000"
			:disableCancel="true"
			:overflow="true"
			:onShow="calculateFromLup1"
			v-model="modalData"
		>
			<template slot="message">
				<v-row dense>
					<!-- REPORTID-->
					<v-col cols="12">
						<pui-text-area
							:id="`reporttext-georepreport`"
							:label="$t('modal.georepreport.fullReportModal.reporttext')"
							v-model="mensajeFormateado"
							required
							toplabel
							disabled
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:modelName="modelName"
			:cancelText="$t('messages.oncancelmessagetext')"
			:okText="$t('messages.onoktext')"
			:titleText="$t('modal.georepreport.georeportinvalidtext')"
			:dialogName="dialogNameInvalidGeoreport"
			:onOk="onOkInvalidGeo"
			><template slot="message">
				<v-container>{{ $t('modal.georepreport.actioninvalidGeoreport') }}</v-container>
			</template>
		</pui-modal-dialog-form>

		<pui-modal-dialog-form
			:modelName="modelName"
			:cancelText="$t('messages.oncancelmessagetext')"
			:okText="$t('messages.onoktext')"
			:titleText="$t('modal.georepreport.georeportinvalidtext')"
			:dialogName="dialogNameInvalidGeoreportForm"
			:onOk="onOkInvalidGeo"
			><template slot="message">
				<v-container>{{ $t('modal.georepreport.actioninvalidGeoreport') }}</v-container>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'georepreport-modals',
	data() {
		return {
			dialogNameInvalidGeoreport: 'invalidGeoreport',
			dialogNameInvalidGeoreportForm: 'invalidGeoreportForm',
			georepreportUploadReportModal: 'georepreportUploadReportAction',
			fullReportModal: 'fullReportModal',
			onShowfullReportModal: 'fullReportModal',
			modalData: {},
			showDialog: true,
			mensajeFormateado: '',
			errorMessages: [],
			urlmodelname: 'georepreport'
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onOkgeorepreportUploadReportModal(modalData) {
			/*const reportLines = modalData.fullreport.split('\n');
			if (reportLines.length === 0 || reportLines[0].trim() === '') {
				this.$puiNotify.error('The first line of the report is missing.', 'ERROR');
				return;
			}*/

			this.errorMessages = [];

			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.georepreport.uploadGeorep.title');
			const message = this.$puiI18n.t('puiaction.notifySuccess');

			/*if (!this.validateMessageFormat(modalData.fullreport)) {
				const errorMessage = title;

				const combinedErrorMessage = errorMessage + ': ' + this.errorMessages.join('\n');

				this.$puiNotify.error(combinedErrorMessage, this.$puiNotify.success(message, title));

				return;
			}*/

			const report = modalData.fullreport;

			const url = '/georepreport/report';

			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					url,
					report,
					() => {
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.modelName);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.success(message, title);

						resolve(true);
					},
					(e) => {
						// Error response, do code and not close modal
						let message = this.$puiI18n.t('puiaction.notifyError');
						if (e.response && e.response.data) {
							message = e.response.data.message;
						}
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
						this.$puiNotify.error(message, title, true);
						resolve(false);
					},
					{
						'Content-Type': 'text/plain'
					}
				);
			});
		},
		validateMessageFormat(fullReport) {
			const trimmedReport = fullReport.trim();

			const lines = trimmedReport.split('\n');

			const firstLinePattern = /^GEOREP [A-Z]+ ?$/;
			if (!firstLinePattern.test(lines[0])) {
				this.errorMessages.push('Invalid format: ' + lines[0]);
				return false;
			}

			const requiredLines = ['A.', 'B.', 'C.'];
			for (const requiredLine of requiredLines) {
				let lineFound = false;
				for (const line of lines) {
					if (line.startsWith(requiredLine)) {
						lineFound = true;
						break;
					}
				}
				if (!lineFound) {
					this.errorMessages.push('Required line ' + requiredLine + ' not found.');
					return false;
				}
			}

			const bLine = lines.find((line) => line.startsWith('B. '));
			if (bLine) {
				const bTime = bLine.substring(3).trim();
				if (!/^\d{6}$/.test(bTime)) {
					this.errorMessages.push('Invalid format of line B: ' + bTime);
					return false;
				}
			} else {
				this.errorMessages.push('Line B not found.');
				return false;
			}

			const followingLinesPattern = /^[A-Z]\. /;
			for (let i = 1; i < lines.length; i++) {
				if (lines[i].trim() === '') {
					continue;
				}

				if (!followingLinesPattern.test(lines[i])) {
					this.errorMessages.push('Line error ' + (i + 1) + ': ' + lines[i]);
					return false;
				}
			}

			return true;
		},
		calculateFromLup1(modalData) {
			const arr = Object.keys(modalData).map((key) => modalData[key]);
			const mensajeFormateado = this.formatearMensaje(arr);

			if (mensajeFormateado.endsWith('false')) {
				this.mensajeFormateado = mensajeFormateado.slice(0, -5);
			} else {
				this.mensajeFormateado = mensajeFormateado;
			}
		},
		formatearMensaje(arrayTexto) {
			let mensajeFormateado = '';

			let ultimaAdicionTabulacion = false;
			let ultimaAdicionSaltoLinea = false;

			arrayTexto.forEach((elemento, indice) => {
				if ((elemento === '\r' || elemento === '\n') && (arrayTexto[indice + 1] === '\r' || arrayTexto[indice + 1] === '\n')) {
					if (!ultimaAdicionTabulacion) {
						mensajeFormateado += '\n';
						ultimaAdicionTabulacion = false;
						ultimaAdicionSaltoLinea = true;
					}
				} else if (
					elemento === ' ' &&
					arrayTexto[indice + 1] === ' ' &&
					arrayTexto[indice + 2] === ' ' &&
					arrayTexto[indice + 3] === ' ' &&
					arrayTexto[indice + 4] === ' '
				) {
					if (!ultimaAdicionTabulacion) {
						mensajeFormateado += '\t';
						ultimaAdicionTabulacion = true;
						ultimaAdicionSaltoLinea = false;
					}

					indice += 3;
				} else if (elemento === ' ') {
					if (!ultimaAdicionTabulacion) {
						mensajeFormateado += ' ';
						ultimaAdicionTabulacion = false;
						ultimaAdicionSaltoLinea = false;
					}
				} else if (elemento === '\r' || elemento === '\n') {
					if (!ultimaAdicionSaltoLinea) {
						mensajeFormateado += '\n';
						ultimaAdicionTabulacion = false;
						ultimaAdicionSaltoLinea = true;
					}
				} else {
					mensajeFormateado += elemento;
					ultimaAdicionTabulacion = false;
					ultimaAdicionSaltoLinea = false;
				}
			});

			return mensajeFormateado;
		},
		onOkInvalidGeo(modalData) {
			const pks = modalData.registries.map(function (e) {
				return { reportid: e.reportid };
			});

			return new Promise((resolve) => {
				this.$puiRequests.postRequest(
					this.$store.getters.getModelByName(this.urlmodelname).url.invalidGeoreport,
					pks,
					() => {
						this.$puiNotify.success(this.$puiI18n.t('puiaction.notifySuccess'), this.$puiI18n.t('action.invalidGeoreport'));
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.urlmodelname);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.urlmodelname);
						resolve(true);
					},
					(e) => {
						this.$puiNotify.error(e.response.data.message, this.$puiI18n.t('action.invalidGeoreport'), 2000, true);
						this.$puiEvents.$emit('onPui-action-running-ended-' + this.urlmodelname);
						this.$puiEvents.$emit('onPui-action-running-showFormLoading-' + this.urlmodelname);

						resolve(false);
					},
					null
				);
			});
		}
	}
};
</script>
